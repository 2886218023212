import React from 'react';


const styles = {
  title: {
    border: 0,
    fontFamily: "inherit",
    fontSize: "100%",
    fontStyle: "inherit",
    fontWeight: "400",
    margin: "0",
    outline: "0",
    padding: "0",
    paddingLeft: "1rem",

    verticalAlign: "baseline",
    '&::before': {
      content: ' ',
      position: "absolute",
      bottom: "0px",
      width: "calc(100% - 25px)",
      borderBottom: "1px solid #EEE",
    },
    '&::after': {
      content: ' ',
      position: "absolute",
      left: "0",
      bottom: "0px",
      width: "25px",
      borderBottom: "3px solid #F33F3F",
      marginLeft: "1rem",
    },
  },
  wrapper: {
    position: "relative",
    width: "100%",
    display: "table",
    paddingBottom: "0.5rem",
    margin: "1rem 0"

  }
};


const Title = ({children}) => <div css={styles.wrapper}><h2 className="h4" css={styles.title}>{children}</h2></div>;


export default Title;

