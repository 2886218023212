import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Title from '../../components/title';

import {withStyles} from '@material-ui/core/styles';

const styles = {
  header: {fontSize: 24, marginBottom: "0.8em"},
  subheader: {textDecoration: 'underline', fontWeight: 'bold'},
  paragraph: {marginBottom: "2em"},
  text: {fontSize: "12px", textAlign: "justify"},
  li: {margin: "auto"},
  centered: {margin: "0 auto"},
  greyHeader: {color: "#888", margin: "1rem 0"},
  tableCell: {textAlign: "center"},

  regulationsWrapper: {marginTop: '4em', fontSize: "12px", textAlign: "justify"},
  regulationsOrderedList: {},
  listStyleAlpha: {listStyleType: 'lower-alpha'},
  listStyleBullet: {listStyleType: 'disc'},

};

const Page = () => (
  <Layout>
    <SEO title="Cennik"
         keywords={[`Paczki`, `Anglia`, `Paczki Polska Anglia`, `Paczki Anglia`, `UK-TRANS`, `Przewozy Okonek`, `Przewozy-Okonek`, `Przewozy.okonek`, `Przeprowadzki anglia.`]}/>
    <Title>CENNIK I REGULAMIN</Title>
    <div className="container" css={styles.header}>
      <div>
        <div css={styles.subheader}>POLSKA – ANGLIA</div>
        <p>PACZKA DO 10 KG – 200ZŁ</p>
        <p>PACZKA 11-30KG – 250ZŁ</p>
        <p>POWYŻEJ 30 KG – 5 ZŁ DOPŁATY ZA KAŻDY 1 KG</p>

        <div css={styles.subheader}>ANGLIA – POLSKA</div>
        <p>PACZKA DO 10 KG – 40F</p>
        <p>PACZKA 11-30KG – 50F</p>
        <p>POWYŻEJ 30 KG – 1F DOPŁATY ZA KAŻDY 1 KG</p>

        <div>PRZY WIĘKSZEJ ILOŚCI PACZEK – ZNIŻKI.</div>

        <div>SPRZĘT RTV/AGD, MOTORY, OKNA, DRZWI ORAZ PRZEPROWADZKI - CENY
          USTALANE INDYWIDUALNIE!!</div>
      </div>

      <div css={styles.regulationsWrapper}>
        <hr />
        <p css={styles.header}>REGULAMIN WYKONYWANIA USŁUG W ZAKRESIE PRZESYŁANIA I DORĘCZANIA PRZESYŁEK PRZEZ FIRMĘ UK-TRANS</p>

        <p>Niniejszy regulamin określa warunki i zasady wykonywania przez firmę UK-TRANS usług w zakresie odbioru,
        przewozu i dostarczania przesyłek na rzecz swoich klientów.
        Firma UK-TRANS wykonuje usługę transportową, jaką jest doręczanie/odbieranie paczek samodzielnie lub przy
        pomocy przewoźników trzecich, firm zewnętrznych z którymi firma ma podpisana stosowną umowę handlową. Za
          działania przewoźników trzecich Zleceniobiorca odpowiada wobec Zleceniodawcy jak za działania własne.</p>

        <ol>
          <li>Przesyłka, po zgłoszeniu oraz zatwierdzeniu przez pracownika firmy, odbierana jest spod wskazanego adresu.</li>
          <li>Zleceniodawca zgłaszając odbiór przesyłki potwierdza znajomość niniejszego Regulaminu.</li>
          <li>Czas dostawy przesyłki wynosi do 10 dni roboczych.</li>
          <li>Każda przesyłka powinna być opatrzona w dane Odbiorcy:
            <ul css={styles.listStyleAlpha}>
              <li>imię i nazwisko,</li>
              <li>ulicę i numer domu,</li>
              <li>kod pocztowy,</li>
              <li>miejscowość,</li>
              <li>telefon kontaktowy.</li>
            </ul>
          </li>
          <li>Z przewozu wykluczone sa przesyłki zawierające:
            <ul css={styles.listStyleBullet}>
              <li>artykuły akcyzowe (alkohol, papierosy, tytoń, itp.)</li>
              <li>leki, substancje psychotropowe, narkotyki, sterydy itp.</li>
              <li>rośliny żywe i zwierzęta</li>
              <li>zwłoki, szczątki ludzkie i zwierzęce</li>
              <li>dzieła sztuki, antyki</li>
              <li>kamienie i metale szlachetne</li>
              <li>szkło</li>
              <li>gotówka, waluty, zbywalne papiery wartościowe, itp.</li>
              <li>rzeczy niebezpieczne lub mogące wyrządzić szkodę osobom lub mieniu, w tym w szczególności: materiały łatwopalne, wybuchowe lub  radioaktywne, substancje żrące lub cuchnące, broń, amunicję</li>
              <li>artykuły podejrzane i niewiadomego pochodzenia (np. z kradzieży).</li>
              <li>inne materiały, których przewóz jest zakazany z mocy ogólnych przepisów prawa</li>
            </ul>
          </li>
          <li>W przypadku stwierdzenia naruszenia przez Nadawcę powyższych postanowień firma UK-TRANS może:
            <ul css={styles.listStyleBullet}>
              <li>odesłać na koszt Nadawcy rzeczy wyłączone z przewozu,</li>
              <li>nałożyć na Nadawcę lub Odbiorcę karę umowną w wysokości pięciokrotnej kwoty należnej z tytułu wykonania usługi,</li>
              <li>skonfiskować niedozwolone przedmioty.</li>
            </ul>
          </li>
          <li>Nadawca gwarantuje, że zawartość każdej przesyłki jest zgodnie ze stanem faktycznym zdeklarowana oraz opisana w liscie przewozowym oraz deklaracji celnej.</li>
          <li>Firma zastrzega sobie prawo do sprawdzenia zawartości przesyłki.</li>
          <li>Opłatę za usługę transportową uiszcza się w formie gotówki lub przelewu.</li>
          <li>Wysokość opłat reguluje aktualny cennik.</li>
          <li>Opłata pobierana jest od każdej pojedynczej przesyłki.</li>
          <li>W przypadku uszkodzenia lub ubytku przesyłki przedstawiciel UK-TRANS obowiązany jest spisać protokół w obecności Odbiorcy, w którym ujęta będzie data i godzina doręczenia oraz opis uszkodzenia lub ubytku przesyłki. Prawidłowo sporządzony protokół (podpisany przez Odbiorcę ) jest podstawą do przyjęcia reklamacji.</li>
          <li>Wskutek przyjęcia przez Odbiorcę przesyłki bez zastrzeżeń wygasają wszelkie roszczenia przeciwko UK- TRANS.</li>
          <li>Szkło, materiały kruche lub artykuły wymagające specjalnych warunkow przewozu przewożone są na wyłączną odpowiedzialność Nadawcy.</li>
          <li>W wypadku utraty, ubytku lub uszkodzenia przesyłki UK-TRANS ponosi odpowiedzialność materialną za koszty związane z odkupieniem lub naprawą przesyłki, nie więcej jednak niż do kwoty stanowiącej górną granicę odpowiedzialności 500 pln.</li>
        </ol>
      </div>

    </div>
  </Layout>
)

export default withStyles(styles)(Page);